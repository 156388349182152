import React, { useEffect, useRef } from "react";
import isMobile from "ismobilejs";
import { useAspectSize } from "../hooks/windowSize";
import { useFullScreen } from "../hooks/fullScreen";

import { chakra } from "@chakra-ui/react";

import {
  AspectRatio,
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { SlotConfig } from "../d";
import { createGameUrl } from "../helper";

type PropsPlay = {
  slot: SlotConfig;
  setPlay: (i: undefined) => void;
};
const Iframe = chakra("iframe");

function Play({ slot, setPlay }: PropsPlay) {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [isFullScreen, toggleHandle] = useFullScreen(ref);
  const size = useAspectSize(16 / 9, isFullScreen ? 0.9 : 0.75);
  const [url, setUrl] = React.useState("");

  const ismobile = isMobile();

  useEffect(() => {
    if (ismobile.any && !isFullScreen) {
      toggleHandle();
    }
  }, [isFullScreen, ismobile, toggleHandle, setPlay]);

  useEffect(() => {
    const start = async () => {
      const url = await createGameUrl(slot.id);
      setUrl(url);
    };
    start();
  }, [slot]);

  if (ismobile.any) {
    return (
      <>
        {/* <Box pos="absolute" width="100%" height="100%" /> */}
        <Flex align="center" justify="center" ref={ref}>
          <Button
            position="absolute"
            top="25px"
            right="15px"
            fontSize="35px"
            onClick={() => setPlay(undefined)}
            style={{
              position: "fixed",
              zIndex: " 222",
              top: " 15px",
              right: " 15px",
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </Button>
          <Iframe
            style={{
              position: "fixed",
              top: "0",
              height: "100%",
              width: "100%",
              zIndex: "100",
            }}
            h={["100vh", "-webkit-fill-available"]}
            w="100%"
            title={slot.name}
            src={url}
          />
        </Flex>
      </>
    );
  }

  return (
    <Flex bg="gray.800" minH="100vh" align="center" justify="center" ref={ref}>
      <Box
        w={size.width}
        maxWidth={window.innerWidth - 300}
        marginRight="150px"
        marginLeft="150px"
        style={{
          overflow: "hidden",
        }}
      >
        <Heading
          fontWeight="medium"
          fontSize={{ base: "3xl", sm: "3xl", md: "5xl" }}
          color="yellow.400"
        >
          <Flex>
            <Box>{slot.name}</Box>
            <Spacer />
            <Box>
              <Button onClick={toggleHandle} marginTop="10px">
                {!isFullScreen ? (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"></path>
                  </svg>
                ) : (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 4H8v4H4v2h6zM8 20h2v-6H4v2h4zm12-6h-6v6h2v-4h4zm0-6h-4V4h-2v6h6z"></path>
                  </svg>
                )}
              </Button>
              <Button marginTop="10px" onClick={() => setPlay(undefined)}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
              </Button>
            </Box>
          </Flex>
        </Heading>
        <AspectRatio ratio={16 / 9}>
          <iframe title={slot.name} src={url} />
        </AspectRatio>
      </Box>
    </Flex>
  );
}

export default Play;
