import { useState, useEffect } from 'react';

function debounce(fn: () => void, ms: number) {
  if (ms === 0) return fn;

  let timer: undefined | number;
  return () => {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      timer = undefined;
      fn();
    }, ms);
  };
}

const useWindowSize = (debounce_ms: number = 0) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, debounce_ms);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [debounce_ms]);

  return size;
};

const useAspectSize = (ratio: number, coef: number = 1.0) => {
  const size = useWindowSize();
  const windowSizeRatio = size.width / size.height;

  if (windowSizeRatio < ratio) {
    return {
      width: size.width * coef,
      height: (size.width / ratio) * coef,
    };
  }
  return {
    width: size.height * ratio * coef,
    height: size.height * coef,
  };
};

export { useWindowSize, useAspectSize };

