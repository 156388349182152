import { useState, useEffect, useCallback } from 'react';

interface DocumentWithFullscreen extends HTMLDocument {
  webkitFullscreenElement?: Element;
  webkitExitFullscreen?: () => void;
}

interface HTMLElementWithFullscreen extends HTMLElement {
  webkitRequestFullscreen?: () => void;
}

export function isFullScreen(el: React.RefObject<Element> | null) {
  const doc = document as DocumentWithFullscreen;
  const fsElement = doc.fullscreenElement ?? doc.webkitFullscreenElement;

  if (el && el.current) {
    return Boolean(fsElement === el.current);
  }

  return Boolean(fsElement);
}

export function useFullScreen<T extends HTMLElementWithFullscreen>(
  el: React.RefObject<T> | null = null,
): [boolean, () => void] {
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleChange = () => {
      setFullScreen(isFullScreen(el));
    };

    document.addEventListener('webkitfullscreenchange', handleChange);
    document.addEventListener('fullscreenchange', handleChange);
    handleChange();
    return () => {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
  }, [el]);

  const toggle = useCallback(() => {
    if (fullScreen) {
      const doc = document as DocumentWithFullscreen;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }

    const target: HTMLElementWithFullscreen =
      (el && el.current) ?? document.documentElement;
    if (target.requestFullscreen) {
      target.requestFullscreen().catch(() => {});
    } else if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen();
    }
  }, [fullScreen, el]);

  return [fullScreen, toggle];
}

