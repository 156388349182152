import React from "react";
import Play from "./components/Play";
import Slots from "./components/Slots";
import { SlotConfig } from "./d";
import { getData } from "./helper";

function App() {
  const [play, setPlay] = React.useState<undefined | number>(undefined);
  const [slots, setSlots] = React.useState<SlotConfig[]>([]);

  React.useEffect(() => {
    const start = async () => {
      const data = await getData();
      setSlots(data);
    };
    start();
  }, []);

  const slot = React.useMemo(() => {
    if (play === undefined) {
      return {} as SlotConfig;
    }
    return slots[play];
  }, [slots, play]);

  return (
    <>
      {play !== undefined ? (
        <Play setPlay={setPlay} slot={slot} />
      ) : (
        <Slots slots={slots} setPlay={setPlay} />
      )}
    </>
  );
}

export default App;
