import { Center, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import { SlotConfig } from "../d";
import GameCard from "./GameCard";

type PropsSlots = {
  slots: SlotConfig[];
  setPlay: (i: number) => void;
};

function Slots({ slots, setPlay }: PropsSlots) {
  return (
    <Stack justify="center">
      <Wrap spacing="40px" justify="center" padding="20px">
        {slots.map((slot, index) => (
          <React.Fragment>
            {!slot.isUnderDevelopment && (
              <WrapItem maxW="md" key={slot.id}>
                <Center>
                  <GameCard {...slot} play={() => setPlay(index)} />
                </Center>
              </WrapItem>
            )}
          </React.Fragment>
        ))}
      </Wrap>
      <Wrap spacing="40px" justify="center" padding="20px">
        {slots.map((slot, index) => (
          <React.Fragment>
            {slot.isUnderDevelopment && (
              <WrapItem maxW="md" key={slot.id}>
                <Center>
                  <GameCard {...slot} play={() => setPlay(index)} />
                </Center>
              </WrapItem>
            )}
          </React.Fragment>
        ))}
      </Wrap>
    </Stack>
  );
}

export default Slots;
