import { Box, Stack, Image, Button, Text } from "@chakra-ui/react";
import isMobile from "ismobilejs";
import { SlotConfig } from "../d";

const getLegacyBackgroundUrl = (image: string) => {
  return `https://static.testing.devp7.com/legacy-lobby/${image}.png`;
};

const getBackgroundUrl = (image: string) => {
  return `https://static.testing.devp7.com/game-showcase/latest/images/${image}.png`;
};

function GameCard(props: SlotConfig & { play: () => void }) {
  const gameName = props.name;
  const mobile = isMobile();
  const isUnderDevelopment = props.isUnderDevelopment;

  return (
    <Stack>
      <Box
        boxSize={mobile ? "sm" : "max"}
        maxHeight="300px"
        position="relative"
      >
        {isUnderDevelopment && (
          <Image
            src="UnderDev_Icon.png"
            position="absolute"
            align="center"
          ></Image>
        )}
        <Image
          src={
            props.codename
              ? getBackgroundUrl(props.codename)
              : getLegacyBackgroundUrl(props.id)
          }
          fallbackSrc="fallback_image.png"
          rounded="md"
        />
      </Box>
      <Text fontSize={mobile ? "xl" : "2xl"} fontWeight="normal" align="center">
        {gameName}
      </Text>
      <Button
        colorScheme="green"
        variant="outline"
        as="button"
        onClick={props.play}
      >
        PLAY
      </Button>
    </Stack>
  );
}

export default GameCard;
